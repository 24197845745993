export default [
  {
    header: 'Services',
  },
  {
    title: 'Documents.self',
    icon: 'BookOpenIcon',
    action: 'read',
    resource: 'ACL',
    // tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Documents.RUT Processing.self',
        route: 'documents-process',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Documents.Previous Analysis.self',
        route: 'documents-list-jobs',
        action: 'read',
        resource: 'ACL',
      },
    ],
  },
]
