export default [
  {
    header: 'Traditionals (?)',
  },
  {
    title: 'Manual Analysis.self',
    icon: 'PenToolIcon',
    action: 'read',
    resource: 'lawyer',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Manual Analysis.Jobs.self',
        route: 'lawyer-list-jobs',
        action: 'read',
        resource: 'lawyer',
      },
    ],
  },
]
