export default [
  {
    header: 'Management',
  },
  {
    title: 'Users.self',
    icon: 'UsersIcon',
    // tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Users.Create.self',
        route: 'users-create',
      },
      {
        title: 'Users.List.self',
        route: 'users-list',
      },
      {
        title: 'Users.Relate.self',
        route: 'users-relate',
      },
    ],
  },
]
