export default [
  {
    title: 'Pool.self',
    icon: 'CpuIcon',
    // tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Pool.Status.self',
        route: 'pool-status',
      },
    ],
  },
]
