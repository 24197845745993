export default [
  {
    header: 'Supervision',
  },
  {
    title: 'Reports.self',
    icon: 'ActivityIcon',
    action: 'read',
    resource: 'oversee',
    // tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Reports.Overview.self',
        route: 'supervision-overview',
        action: 'read',
        resource: 'oversee',
      },
      {
        title: 'Reports.Requests.self',
        route: 'supervision-requests',
        action: 'read',
        resource: 'oversee',
      },
      {
        title: 'Reports.Analysis.self',
        route: 'supervision-analysis',
        action: 'read',
        resource: 'oversee',
      },
    ],
  },
]
